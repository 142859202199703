import React from 'react';
import {
  ActivityIndicator,
  Alert,
  LayoutAnimation,
  ScrollView,
  StatusBar,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import Toast from 'react-native-toast-message';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import Text from '../components/CustomText';
import {Input} from '../components/Input/Input';

import * as authActions from '../store/actions/authActions';
import style from '../constants/style';
import { Button } from '../components/Buttons';
import Dropdown from '../components/Dropdown';
import { InputImitation } from '../components/Input/InputImitation';
import { STORE_CATEGORIES } from '../constants/constants';
import { XCloseButton } from '../components/XCloseButton';
import config from '../constants/config';
import Colors from '../constants/Colors';
// import * as kidAuthActions from '../store/actions/kids/authActions';

const FancyDropdown = ({ value, setValue, values }) => {
  const [visible, setVisible] = React.useState(false);

  return <><InputImitation
          title={"Category"}
          containerStyle={{}}
          inputStyle={{alignItems: 'center'}}
          value={value}
          renderValue={() => {
              return value ?
              <View
                  key={value} style={{
                  backgroundColor: values.find(item=>item.title===value)?.color,
                  width: undefined, borderRadius: 10, marginVertical: 5 }}>
                  <Text style={{paddingHorizontal: 20, paddingVertical: 5 }}>{value}</Text>
              </View>
              : <Text style={{ fontSize: 14, color: '#282828', opacity: 0.5}}>Select</Text>
          }}
          // placeholder={'Select Category'}
          placeholderTextColor={'rgba(40, 40, 40, 0.5)'}
          onPress={() => {
              setVisible(val => !val);
              LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
          }}
        />
        {visible && <View style={{ width: '100%', paddingLeft: 30, alignItems: 'flex-start', backgroundColor: 'white' }}>
          {values.map(item => (
          <TouchableOpacity
              onPress={() => {
                  setVisible(val => !val);
                  setValue(item.title);
                  LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
              }}
              key={item.title} style={{ backgroundColor: item.color, width: undefined, borderRadius: 10, marginVertical: 5 }}>
              <Text style={{paddingHorizontal: 20, paddingVertical: 5 }}>{item.title}</Text>
          </TouchableOpacity>))}
        </View>}
        </>
    }

// export default
class AdminAddStoreItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      price: '',
      description: '',
      image: '',
      link: '',
      merchantname: '',
    };

    this.onAddStoreItemPress = this.onAddStoreItemPress.bind(this);
  }


  componentDidMount() {
    // this._bootstrapAsync();
  }

  areAllFieldsFilled() {
    console.log('areAllFieldsFilled', this.state.title, this.state.price, this.state.description, this.state.image, this.state.link, this.state.merchantname);
    return this.state.title && this.state.price && this.state.description && this.state.image && this.state.link && this.state.merchantname;
  }

  onClosePress = () => {
    console.log({navigation: this.props.navigation});
    this.props.navigation.navigate('Settings');
  }

  // Fetch the token from storage then navigate to our appropriate place
  onAddStoreItemPress() {
    if (!this.areAllFieldsFilled()) {
      Toast.show({
        type: 'error',
        text1: 'Add info',
        text2: 'Please fill all fields'
      });
      return;
    }
    const body = {
      title: this.state.title,
      price: this.state.price,
      description: this.state.description,
      image: this.state.image,
      link: this.state.link,
      merchantname: this.state.merchantname,
      category: this.state.category,
      id: this.state.id,
    };

    this.setState({ loading: true });

    return fetch(config.url + 'addStoreItemToDatabase', {
        method: 'POST',
        body: JSON.stringify(body)
    })
    .then(result => {
      return result.json()
    })
    .then((res) => {
      if (res.success) {
        Toast.show({
          type: 'success',
          text1: 'Success',
          text2: 'Store item successfully added'
        });
        this.setState({
          title: '',
          price: '',
          description: '',
          image: '',
          link: '',
          merchantname: '',
          category: '',
          id: '',
        })
      } else {
        Toast.show({
          type: 'error',
          text1: 'Error',
          text2: 'Something went wrong'
        });
      }
    })
    .catch(err => {
      console.log('err', err);
      Toast.show({
        type: 'error',
        text1: 'Error',
        text2: 'Something went wrong'
      });
      // Alert.alert('Error', err.message || err);
    })
    .finally(() => {
      this.setState({ loading: false });
    });
  }

  // Render any loading content that you like here
  render() {
    const { loading } = this.state;
    return (
      <>
      {<XCloseButton containerStyle={styles.close} onPress={this.onClosePress} />}
      {loading ? <View style={styles.loadingContainer}><ActivityIndicator color={Colors.green1} size={'large'} /></View> : null }
      <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
        <View style={styles.itemsContainer}>
          <Text style={styles.title}>Add new store item</Text>
          <Input
            title="Title"
            containerStyle={styles.input}
            value={this.state.title}
            onChangeText={title => this.setState({ title })}
          />
          <Input
            title="Description"
            containerStyle={styles.input}
            value={this.state.description}
            onChangeText={description => this.setState({ description })}
          />
          <FancyDropdown
            value={this.state.category}
            setValue={category => this.setState({ category })}
            values={STORE_CATEGORIES}
          />
          <Input
            title="Price"
            containerStyle={styles.input}
            value={this.state.price}
            onChangeText={price => this.setState({ price })}
          />
          <Input
            title="Image"
            containerStyle={styles.input}
            value={this.state.image}
            onChangeText={image => this.setState({ image })}
          />
          <Input
            title="Link"
            containerStyle={styles.input}
            value={this.state.link}
            onChangeText={link => this.setState({ link })}
          />
          <Input
            title="ID"
            containerStyle={styles.input}
            value={this.state.id}
            onChangeText={id => this.setState({ id })}
          />
          <Input
            title="Merchant Name"
            containerStyle={styles.input}
            value={this.state.merchantname}
            onChangeText={merchantname => this.setState({ merchantname })}
          />
          <Button
            styles={[styles.button, {opacity: this.areAllFieldsFilled() ? 1 : 0.6}]}
            title="Add"
            // disabled={!this.areAllFieldsFilled()}
            // disabled={true}
            onPress={this.onAddStoreItemPress}
          />
        </View>
        {/* {Input} */}
      </ScrollView>
      </>
    );
  }
}


export default connect(state => {
	// console.log(Object.keys(state.vacancies));
	return ({
		// fullState: state,
		auth: state.auth,
		// kidAuth: state.kidAuth,
	})
},
	(dispatch) => ({
		actions: bindActionCreators({ ...authActions, }, dispatch)
	})
)(AdminAddStoreItem);

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    zIndex: 1,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    position: 'absolute',
    backgroundColor: Colors.transparentWhite,
  },
  container: {
    flex: 1,
    backgroundColor: '#fff',
    paddingBottom: 40,
  },
  contentContainer: {
    alignItems: 'center',
    paddingBottom: 20,
  },
  itemsContainer: {
    width: '100%',
    maxWidth: 500,
    paddingHorizontal: 20,
  },
  title: {
    ...style.h1,
    textAlign: 'center',
    marginVertical: 20,
  },
  input: {
    marginVertical: 10,
  },
  button: {
    marginVertical: 20,
  },
  close: {
    position: 'absolute',
    top: 20,
    right: 20,
    zIndex: 1,
  }
});
