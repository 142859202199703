import React, { useRef, useEffect } from 'react';

import { Animated, View, Platform, Dimensions } from 'react-native';
// import { useHover, useFocus, useActive } from 'react-native-web-hooks';

import {
    useHover,
    useFocus,
    useActive,
    useDimensions,
    Resizable,
    Hoverable,
} from 'react-native-web-hooks';

import TouchableNativeFeedback from '../components/TouchableFeedback';
import Text from '../components/CustomText';

import Colors from '../constants/Colors';
import globalStyle, { defaultStyles } from '../constants/style';

const { height, width } = Dimensions.get('window');
const vmin = Math.min(...[height, width]);

export const JobsButton = ({ children, styles }) => {
    // Create a ref to bind the hooks to
    const ref = useRef(null);
    const scaleValue = new Animated.Value(0);
    // const ref = React.createRef();
    // Pass that ref to the hooks...
    const { isHovered } = useHover(ref);
    const { isFocused } = useFocus(ref);
    const { isActive } = useActive(ref);

    useEffect(() => {
        Animated.timing(scaleValue, {
            toValue: isHovered ? 1 : 0,
            duration: 1000,
        }).start();
    }, [isHovered]);

    const scale = scaleValue.interpolate({
        inputRange: [0, 1],
        outputRange: [1, 1.1],
    });

    return (
        <Animated.View
            onPress={() => {}}
            tabIndex={0}
            ref={ref}
            style={[
                {
                    position: 'absolute',
                    top: -0.3 * vmin,
                    right: 0.21 * vmin,
                    justifyContent: 'center',
                    width: vmin * 0.25,
                    height: vmin * 0.25,
                    aspectRatio: 1,
                    borderRadius: vmin * 0.25,
                    backgroundColor: '#EE5060',
                },
                { transform: [{ scale: scale }] },
            ]}
        >
            {children}
        </Animated.View>
    );
};

export const SavingsButton = ({ children, styles }) => {
    // Create a ref to bind the hooks to
    const ref = useRef(null);
    const scaleValue = new Animated.Value(0);

    // const ref = React.createRef();
    // Pass that ref to the hooks...
    const { isHovered } = useHover(ref);
    const { isFocused } = useFocus(ref);
    const { isActive } = useActive(ref);

    useEffect(() => {
        Animated.timing(scaleValue, {
            toValue: isHovered ? 1 : 0,
            duration: 1000,
        }).start();
    }, [isHovered]);

    const scale = scaleValue.interpolate({
        inputRange: [0, 1],
        outputRange: [1, 1.1],
    });

    return (
        <Animated.View
            onPress={() => {}}
            tabIndex={0}
            ref={ref}
            style={[
                {
                    top: -0.07 * vmin,
                    right: 0.17 * vmin,
                    justifyContent: 'center',
                    width: vmin * 0.25,
                    height: vmin * 0.25,
                    aspectRatio: 1,
                    borderRadius: vmin * 0.25,
                    backgroundColor: '#4AA0C6',
                },
                { transform: [{ scale: scale }] },
            ]}
        >
            {children}
        </Animated.View>
    );
};

export const buttonStylesV2 = {
    styles: {
        ...defaultStyles.shadow,
        width: '100%',
        marginVertical: vmin * 0.1,
        backgroundColor: Colors.white,
        alignSelf: 'flex-end',
        // marginVertical: 10,
        // opacity: this.allowanceChanged() ? 1 : 0.5,
    },
    textStyle: { ...globalStyle.h3, ...defaultStyles.bold, marginVertical: 15, color: Colors.green1 },
};

export const Button = ({ children, styles, title, onPress, textStyle, ...otherProps }) => {
    // Create a ref to bind the hooks to

    return (
        <Hoverable>
            {(isHovered) => (
                <TouchableNativeFeedback
                    style={[
                        {
                            marginVertical: 10,
                            backgroundColor: Colors.green1,
                            borderRadius: 20,
                            // boxShadow:'2px 2px 15px #272827',
                            padding: 5,
                            // transform:[{scale:isHovered?1.1:1}],
                        },
                        styles,
                    ]}
                    onPress={onPress}
                    {...otherProps}
                >
                    <Text
                        style={[
                            {
                                color: 'white',
                                textAlign: 'center',
                                // width: 100,
                                margin: 5,
                            },
                            textStyle,
                        ]}
                    >
                        {title}
                    </Text>
                </TouchableNativeFeedback>
            )}
        </Hoverable>
    );
};
