import React from 'react';
import { Platform, Image, Dimensions } from 'react-native';
import { createSwitchNavigator } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';
import { createBottomTabNavigator } from 'react-navigation-tabs';

import Store from '../screens/Store';

import Home from '../screens/Parents/Home';
import Dashboard from '../screens/Parents/Dashboard';
import Settings from '../screens/Parents/Settings';
import Chequing from '../screens/Parents/Chequing';
import Jobs from '../screens/Parents/Jobs';
import Goals from '../screens/Parents/Goals';
import Purchases from '../screens/Parents/Purchases';
// import TestScreen from "../screens/TestScreen";
// import StoreParents from "../screens/Parents/Store";
import StoreItem from '../screens/Parents/StoreItem';

import Welcome from '../screens/Parents/NewKid/Welcome';
import Parent from '../screens/Parents/NewKid/Parent';
import Profile from '../screens/Parents/NewKid/Profile';
import FirstJob from '../screens/Parents/NewKid/FirstJob';
import SkipFullProcess from '../screens/Parents/NewKid/SkipFullProcess';
import Allowance from '../screens/Parents/NewKid/Allowance';
import Expense from '../screens/Parents/NewKid/Expense';
import AdditionalExpense from '../screens/Parents/NewKid/AdditionalExpense';
import AdminAddStoreItem from '../screens/AdminAddStoreItem';


import ChequingSetup from '../screens/Parents/NewKid/Chequing';
import SavingsSetup from '../screens/Parents/NewKid/Savings';
import Walkthrough from '../screens/Parents/NewKid/Walkthrough';

import Security from '../screens/Parents/Settings/Security';
import ChequingSettings from '../screens/Parents/Settings/Chequing';
import SavingsSettings from '../screens/Parents/Settings/Savings';
import RegularSettings from '../screens/Parents/Settings/Regular';
import ExpenseSettings from '../screens/Parents/Settings/Expense';
import NewParent from '../screens/Parents/Settings/NewParent';
import AccountSettings from '../screens/Parents/AccountSettings';

import title from '../helpers/generateTitle';

import { defaultStyles, styleHook } from '../constants/style';
// import { View } from 'react-native-web';

const bottomBarIcons = {
    'Main': { source: require('assets/BottomBar/HomeIcon.png'), focusedSource: require('assets/BottomBar/HomeActiveIcon.png') },
    'Store': { source: require('assets/BottomBar/StoreIcon.png'), focusedSource: require('assets/BottomBar/StoreActiveIcon.png') },
    'Account': { source: require('assets/BottomBar/ProfileIcon.png'), focusedSource: require('assets/BottomBar/ProfileActiveIcon.png') },
    Settings: { source: require('assets/BottomBar/SettingsIcon.png'), focusedSource: require('assets/BottomBar/SettingsActiveIcon.png') },
};

export const NewKid = createSwitchNavigator(
    {
        // Welcome,
        // ParentSetup: { screen: Parent },
        // Contracts,
        // PrivacyPolicy,
        // TermsAndConditions,
        Profile,
        FirstJob,
        SkipFullProcess,
        Allowance,
        Expense,
        AdditionalExpense,
        ChequingSetup,
        SavingsSetup,
        Walkthrough,
    },
    {
        navigationOptions: ({ navigation }) => {
            const current =
                navigation.state.routes &&
                navigation.state.routes.length &&
                navigation.state.routes[navigation.state.routes.length - 1]
                    .routeName;

            console.log('Asdjaslkda - ', current);

            return {
                // headerMode: 'none',
                // header: null,
                // title: current && title(current),
                title: 'AAAAS',
            };
        },
    }
);

export const ParentSettingsStack = createSwitchNavigator(
    {
        Settings: { screen: Settings, path: '' },
        Security,
        AdminAddStoreItem,
    },
    {
        navigationOptions: () => ({
            headerMode: 'none',
        }),
    }
);

const MainStack = createStackNavigator({
    Home,
    Dashboard,
    Goals,
    Jobs,
    Purchases,
    ChequingSettings: { screen: ChequingSettings, path: 'Chequing' },
    SavingsSettings: { screen: SavingsSettings, path: 'Savings' },
    RegularSettings: { screen: RegularSettings, path: 'Regular' },
    ExpenseSettings: { screen: ExpenseSettings, path: 'Expense' },
    Chequing: { screen: Chequing, path: ':cards' },
}, { headerMode: 'none',
navigationOptions: {
    cardStyle: {
        borderWidth: 4,
        paddingTop: 400.
    },
    },
});

const StoreStack = createStackNavigator({
    Store,
    StoreItem: { screen: StoreItem, path: 'Store/:store/:index' },
});

const AccountStack = createStackNavigator({
    AccountSettings,
    NewKid,
    // ChequingSettings: { screen: ChequingSettings, path: 'Chequing' },
    // SavingsSettings: { screen: SavingsSettings, path: 'Savings' },
    // RegularSettings: { screen: RegularSettings, path: 'Regular' },
    // ExpenseSettings: { screen: ExpenseSettings, path: 'Expense' },
    NewParent,
}, { headerMode: 'none',});

// TODO finish this func
// const recursivelyFindRouteName = (navigation) => {
//     if (navigation?.routes?.length) {
//         return recursivelyFindRouteName(
//             navigation.routes[navigation.routes.length - 1]
//         );
//     }

//     return navigation?.routes?.routeName;
// };

export const ParentStackNavigator = createBottomTabNavigator(
              {
                  Main: MainStack,
                  Store: StoreStack,
                  Account: AccountStack,
                //   NewKid,
                  Settings: { screen: ParentSettingsStack, path: 'Settings' },
                  // TestScreen,
                  // SignUp
              },
              {
                  initialRouteName: 'Main',
                  // headerMode: 'screen',
                  defaultNavigationOptions: {
                    //   header: null,
                      // style, name, navigation, signOut, vmin, layout, clearAuth, pressNext, dashboard
                      // header: ({navigation})=> <ParentHeaderCard navigation={navigation} name={'test'} layout={{width: 200, height: 800}} vmin={200} />,
                  },
                  headerMode: 'none',
                  header: null,
                  tabBarOptions: {
                    showLabel: false,
                    style: {
                        // paddingTop: 300,
                        borderTopWidth: 0, backgroundColor: 'white',
                     ...defaultStyles.shadow,
                      borderTopLeftRadius: 20, borderTopRightRadius: 20,
                    position:'absolute',
                    bottom: 0,
                    //   overflow: 'hidden',
                    //   padding:10,
                    width: Dimensions.get('screen').width,
                    height: 64,
                    zIndex: 8
                    },
                  },
                  defaultNavigationOptions: ({ navigation }) => ({
                    cardStyle: {
                        borderWidth: 4,
                        paddingTop: 400,
                    },
                    tabBarIcon: (props) => {
                        const { focused, horizontal, tintColor } = props;
                        const { routeName } = navigation.state;

                        const bottomBarImage = bottomBarIcons[routeName][focused ? 'focusedSource' : 'source'];
                        // const bottomBarImage = bottomBarIcons['Main'].source;

                      // You can return any component that you like here!
                      return <Image resizeMode='contain' source={bottomBarImage} style={{width: 30, height: 30}} />;
                    },
                  }),
                  navigationOptions: ({ navigation }) => {
                    const currentRoot =
                        navigation?.state?.routes?.[
                            navigation?.state?.routes?.length - 1
                        ];
                    const current = currentRoot?.index
                        ? currentRoot?.routes?.[currentRoot?.index]?.routeName
                        : currentRoot?.routeName;
                    //   const current = recursivelyFindRouteName(navigation?.state);

                    //   console.log(
                    //       'navigation.state.routes -',
                    //       navigation?.state?.routes
                    //   );

                    return {
                        headerMode: 'none', title: current && title(current) };
                },
              }
          );
// stackNavigator.path = '';
