import React, { useState, useEffect, useCallback } from 'react';
import {
    ActivityIndicator,
    SafeAreaView,
    Animated,
    Platform,
    StatusBar,
    Image,
    StyleSheet,
    View,
    Text,
    TextInput,
    ImageBackground,
    Keyboard,
} from 'react-native';
import Toast from 'react-native-toast-message';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFonts } from 'expo-font';

// import * as SplashScreen from 'expo-splash-screen';

import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { persistStore, persistCombineReducers } from 'redux-persist';

import thunk from 'redux-thunk';

import { PersistGate } from 'redux-persist/integration/react';

import firebase from 'firebase/app';
import 'firebase/auth';

import * as reducers from './store/reducers/parents';
import * as kidReducers from './store/reducers/kids';
import auth from './store/reducers/auth';

import config from './constants/config';
import Colors from './constants/Colors';
import { FONTS } from './constants/fonts';
import { CustomModal } from './components/Modal/CustomModal';

// const auth = firebase.auth
// console.log = () => null;

if (!__DEV__) {
    console.log = () => {};
}

console.log('GLOBAL LOCATION', global.location);

var firebaseConfig = {
    apiKey: config.apiKey,
    authDomain: config.authDomain,
    databaseURL: config.databaseURL,
    projectId: config.projectId,
    storageBucket: config.storageBucket,
    messagingSenderId: config.messagingSenderId,
    appId: config.appId,
    ...(config.measurementId ? { measurementId: config.measurementId } : null),
};

firebase.initializeApp(firebaseConfig);

firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
        console.log('SIGNED IN', user.email);
        // saveUser
        // User is signed in.
    } else {
        console.log('SIGNED OUT');
    }
});

const persistConfig = {
    key: 'root',
    storage: AsyncStorage,
    // stateReconciler: autoMergeLevel2,
};

// persistCombineReducers
const persistedReducer = persistCombineReducers(persistConfig, {
    ...reducers,
    ...kidReducers,
    auth,
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        state = undefined;
    }

    return persistedReducer(state, action);
};

export const store = applyMiddleware(thunk)(createStore)(rootReducer);
export const persistor = persistStore(store);

export const tooltipRef = React.createRef();

export const Tooltip = React.forwardRef((props, ref) => {

    return <TextInput ref={ref}
    editable={false} defaultValue={''}
    style={{
        position: 'absolute', color: 'white', padding: 15, zIndex: 10,
        borderWidth: 0.5, borderColor: Colors.gray1,
        borderRadius: 10, top: -100,
    }} />
})


export default function App(props) {
    const { appType } = props;
    // const [isLoadingComplete, setLoadingComplete] = useState('splash11');

    // TODO IMPORT FONTS
    // const fontsLoaded = true;
    const [fontsLoaded] = useFonts({
        [FONTS.FONT_DEFAULT]: require('assets/fonts/carmen-sans/Carmen_Sans_Regular.otf'),
        [FONTS.FONT_BOLD]: require('assets/fonts/carmen-sans/Carmen_Sans_Bold.otf'),
    });

    console.log('pennygem-kids - ',appType);
    const renderAppNavigator = useCallback(() => {
        // return <View />
        const AppNavigator = props.navigator;
        return <AppNavigator />

    });

    if (!fontsLoaded) {
        // console.log('not loaded', fontsLoaded);
        // SplashScreen.preventAutoHideAsync();

        // return <AppLoading />;
        return <View />;
    } else {
        // console.log('loaded!!!!!!!', fontsLoaded);
        // SplashScreen.hideAsync();

        return (
            <Provider store={store}>
                <PersistGate
                    loading={<ActivityIndicator />}
                    persistor={persistor}
                >
                    <View style={styles.container}>
                        <CustomModal />
                        {Platform.OS === 'ios' && (
                            <StatusBar barStyle='default' />
                        )}
                        <SafeAreaView
                            style={{ flex: 1 }}
                            onStartShouldSetResponder={Keyboard.dismiss}
                        >
                            <Tooltip ref={tooltipRef} />
                            {/* <AppNavigator /> */}
                            {renderAppNavigator()}
                        </SafeAreaView>
                    </View>
                    <Toast />
                </PersistGate>
            </Provider>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
    },
});
